<template>
  <vx-card>
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removeActive"
      >
        <!--  รหัส  -->
        <vs-row
          style="font-weight:bold; margin-top:30px;"
          class="row-form"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
            >ลบข้อมูล เลขที่ออเดอร์ {{ this.removeOrderNumberLabel }}</vs-col
          >
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button
              @click="confirmRemove()"
              style="margin:10px;"
              color="success"
              type="filled"
              >ตกลง</vs-button
            >
            <vs-button
              @click="removeActive = false"
              style="margin:10px;"
              color="danger"
              type="filled"
              >ยกเลิก</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>


    <!-- ค้นหาด้วยวันที่  -->
    <!-- <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-align="center"
        vs-lg="2"
        vs-md="2"
        vs-sm="2"
        vs-xs="6"
        >วันที่ลงรายการ</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="4"
        vs-sm="4"
        vs-xs="6"
      >
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <v-date-picker
                class="custom-date-picker"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
                mode="range"
                v-model="calendarData"
              />
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-w="12"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-w="6"
            class="px-2"
          >
            <vs-button
              @click="searchBtn()"
              color="primary"
              type="filled"
              class="w-full"
            >
              ค้นหา
            </vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-w="6"
            class="px-2"
          >
            <vs-button
              @click="resetBtn()"
              color="warning"
              type="filled"
              class="w-full"
            >
              รีเซ็ต
            </vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-w="4"
            class="px-2"
          >
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row> -->
    <!-- end: ค้นหาด้วยวันที่  -->
    <vs-table pagination max-items="20" search :data="saleItems">
      <template slot="thead">
        <vs-th>ลงรายการ</vs-th>
        <vs-th>เลขออเดอร์</vs-th>
        <vs-th>ยอดรวม</vs-th>
        <vs-th>ช่องทางการชำระเงิน</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr v-for="(tr, index) in data" :key="index" :data="tr">
          <vs-td>
            {{ tr.date }}<br />
            {{ tr.email }}
          </vs-td>
          <vs-td>
            <span style="color:#2372CE; " >
              {{ tr.orderNumber }}
            </span>
            <br/>
            <span style="font-size:0.8rem; ">
              <!-- <pre>{{ JSON.stringify(tr, null, 2) }}</pre> -->
              {{ tr.customerName }}
            </span>
            <br/>
            <span style="font-size:0.8rem; ">
              {{ tr.customerPhone }}
            </span>
          </vs-td>
          <vs-td>{{ tr.totalPrice }}</vs-td>
          <vs-td>
            <!-- <div
              class="flex justify-center items-center"
              :style="{
                width: '100px',
                color: tr.paymentType == 1 ? '#FF9800' : '#8BC34A',
                backgroundColor: tr.paymentType == 1 ? '#FFECB3' : '#DCEDC8'
              }"
            > -->
              <span class="pt-2 pb-2">
                {{ tr.paymentMethod }}
              </span>
            <!-- </div> -->
          </vs-td>
          <vs-td>
            <!-- <i
              @click="takeNote($event, indextr)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >speaker_notes</i
            >
            <br /> -->
            <i
              @click="handlePrint(tr.saleOrderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >print</i
            >
            <i
              v-if="$acl.check('owner')"
              @click="openRemovePanel($event, tr)"
              style="color:red; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >delete_forever</i
            >
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import axios from "axios";
import shapeFormat from "@/mixins/shapeFormat.js";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      calendarData: "",
      page: 1,
      max: 3,
      saleItems: [
        // {
        //   date: "2021-06-13",
        //   email: "example@example.com",
        //   orderNumber: "OR121030B0002-3",
        //   totalPrice: 1230,
        //   paymentType: 1 // 1 = บัตรเครดิต, 2 = โอนเงิน
        // },
      ],
      removeActive: false,
      removeOrderNumberLabel: "",
      removeSaleOrderId: ""
    };
  },
  methods: {
    async handlePrint(saleOrderId) {


      let resp = null;
      let itemCount = 0;

      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/v2/count-sale-order-item" ,
          {saleOrderId : saleOrderId},
          this.$store.state.auth.apiHeader
        );
        itemCount = resp.data;

        console.log(itemCount);

      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }


      var weighted_score = this.calculateScoreForAbbReceiptSo(0, itemCount);
      var path = "/pages/abb-receipt-so/" + weighted_score.toString()  + "/so/" + saleOrderId;

      this.$router.push(path);
      
      // this.$router.push({
      //   name: "sale-order-detail",
      //   params: {
      //     orderId: saleOrderId
      //   }
      // });
    },
    openRemovePanel(event, tr) {
      event.stopPropagation();
      this.removeActive = true;
      this.removeOrderNumberLabel = tr.orderNumber;
      this.removeSaleOrderId = tr.saleOrderId;
    },
    async confirmRemove() {
      const url = `${this.$store.state.apiURL}/api/v2/DeleteSaleOrder`;
      let res = null;
      res = await axios
        .post(
          url,
          {
            saleOrderId: this.removeSaleOrderId
          },
          this.$store.state.auth.apiHeader
        );

        // .then(res => {
          const data = res.data;
          console.log(data);
          if (data.success) {
            this.removeActive = false;
            this.fetchSaleOrderList();
          }
        // })
        // .catch(res => {
        //   console.log(res);
        // });
    },
    async fetchSaleOrderList() {

      // console.log("@@@@@@@@@@@@fetchSaleOrderList");

      const url = `${this.$store.state.apiURL}/api/v2/GetSaleOrderList`;

      let res = null;
      res = await axios.post(
          url,
          {
            filter: false
          },
          this.$store.state.auth.apiHeader
        );
        // .then(res => {
          let data = res.data.orderList;
          // let data = [];
          console.log(res.orderList);

          this.saleItems = [];

          data.forEach(item => {
            if (!item.cancel) {
              let paymentType;
              let date = this.formatDate(item.createDateTime);
              // const dateFormat = new Date(item.createDateTime);
              // let date = `${dateFormat.getFullYear()}-${dateFormat
              //   .getMonth()
              //   .toString()
              //   .padStart(2, "0")}-${dateFormat
              //   .getDay()
              //   .toString()
              //   .padStart(2, "0")}`;

              if (item.paymentMethod === "บัตรเครดิต") {
                paymentType = 1;
              } else {
                paymentType = 2;
              }

              this.saleItems.push({
                date: date,
                email: item.createBy,
                orderNumber: item.orderNumber,
                totalPrice: item.priceTotal.toLocaleString(),
                paymentType: paymentType, // 1 = บัตรเครดิต, 2 = โอนเงิน
                paymentMethod: item.paymentMethod,
                saleOrderId: item.saleOrderId,
                customerName: item.customerName,
                customerPhone: item.customerPhone,
                itemCount: item.saleOrderItem
              });
            }
          });

        // });
    }
  },
  async mounted() {

    // console.log("HELLO");
    
    await this.fetchSaleOrderList();
    //  console.log("MOUNTED END");
    
  }
};
</script>

<style scoped>
.custom-date-picker {
  width: 100%;
  font-size: 14px;
  z-index: 999 !important;
}
</style>
